import { ActionTree } from 'vuex';
import { api } from 'boot/axios';
import {
  Cookies, LocalStorage, Dialog,
} from 'quasar';
import { CredentialsInterface } from 'src/interfaces/UserInterfaces';

import { AxiosResponse } from 'axios';

import { ApiRestErrorInterface } from 'src/interfaces/ApiRestErrorInterface';
import { UserInfoInterface, UserNameInfoInterface } from 'src/interfaces/UserInfoInterface';
import { StateInterface } from '../index';
import { UserStateInterface } from '.';

const optionsCookies = {
  path: '/',
};

const actions: ActionTree<UserStateInterface, StateInterface> = {

  async login(context, payload: CredentialsInterface): Promise<void> {
    return api
      .post('/login', {
        username: payload.email,
        password: payload.password,
      })
      .then((response: AxiosResponse<UserInfoInterface>) => {
        if (response.data && response.data.token) {
          if (!response.data.code) {
            const jwt = `Bearer ${response.data.token}`;
            Object.assign(api.defaults, { headers: { Authorization: jwt } });
            context.commit('SET_CURRENT_USER', response.data);
            Cookies.set('jwt', jwt, optionsCookies);
            Cookies.set('expirationDate', response.data.expirationDate.toString(), optionsCookies);
            Cookies.set('defaultPropertyId', response.data.defaultPropertyId.toString(), optionsCookies);
            Cookies.set('userId', response.data.userId.toString(), optionsCookies);
            Cookies.set('userName', response.data.userName, optionsCookies);
            Cookies.set('userFirstName', response.data.userFirstName, optionsCookies);
            Cookies.set('userEmail', response.data.userEmail, optionsCookies);
            Cookies.set('userPhone', response.data.userPhone, optionsCookies);
            Cookies.set('availabilityRuleFields', JSON.stringify(response.data.availabilityRuleFields), optionsCookies);
            Cookies.set('userImageUrl', response.data.userImageUrl, optionsCookies);

            LocalStorage.set('userImageBase64', response.data.userImageBase64);
          }
        }
      }).catch((error: ApiRestErrorInterface) => {
        if (error.response.status === 401) {
          Dialog.create({
            title: error.response.data.name,
            message: error.response.data.description,
            html: true,
          });
        }
      });
  },
  recoverCookies(context): void {
    const jwt = Cookies.get('jwt');
    const expirationDate = Cookies.get('expirationDate');
    const defaultPropertyId = Cookies.get('defaultPropertyId');
    const userId = Cookies.get('userId');
    const userName = Cookies.get('userName');
    const userFirstName = Cookies.get('userFirstName');
    const userEmail = Cookies.get('userEmail');
    const userPhone = Cookies.get('userPhone');
    const userImageBase64 = LocalStorage.getItem('userImageBase64');
    const availabilityRuleFields = Cookies.get('availabilityRuleFields');
    const userImageUrl = Cookies.get('userImageUrl');
    if (jwt
      && expirationDate
      && defaultPropertyId
      && userId
      && userName
      && availabilityRuleFields) {
      const today = new Date();
      if ((parseInt(Cookies.get('expirationDate'), 10) * 1000) >= today.getTime()) {
        context.commit('SET_CURRENT_USER', {
          jwt,
          expirationDate,
          defaultPropertyId,
          userId,
          userName,
          userFirstName,
          userEmail,
          userPhone,
          userImageBase64,
          availabilityRuleFields,
          userImageUrl,
        });

        Object.assign(api.defaults, { headers: { Authorization: jwt, 'Content-Type': 'application/json' } });
      } else {
        context.commit('CLEAR_CURRENT_USER');
        void context.dispatch('properties/reset', {}, { root: true });
      }
    }
  },

  reset(context) {
    Cookies.remove('jwt');
    Cookies.remove('userId');
    Cookies.remove('userName');
    Cookies.remove('userFirstName');
    Cookies.remove('userEmail');
    Cookies.remove('userPhone');
    Cookies.remove('defaultPropertyId');
    Cookies.remove('expirationDate');
    Cookies.remove('availabilityRuleFields');
    Cookies.remove('userImageUrl');
    LocalStorage.remove('userImageBase64');
    context.commit('CLEAR_CURRENT_USER');
  },

  clearUser(context) {
    context.commit('CLEAR_CURRENT_USER');
  },

  async fetchUser(context, userId: number) {
    return api
      .get(`/users/${userId}`).then((response: AxiosResponse<UserInfoInterface>) => {
        if (response.data) {
          context.commit('SET_CURRENT_USER', response.data);
        }
      });
  },

  async updateUser(context, payload: UserInfoInterface) {
    return api
      .patch(`/users/p/${payload.userId}`, payload).then(() => {
        Cookies.set('expirationDate', payload.expirationDate.toString(), optionsCookies);
        Cookies.set('defaultPropertyId', payload.defaultPropertyId.toString(), optionsCookies);
        Cookies.set('userId', payload.userId.toString(), optionsCookies);
        Cookies.set('userName', payload.userName, optionsCookies);
        Cookies.set('userFirstName', payload.userFirstName, optionsCookies);
        Cookies.set('userEmail', payload.userEmail, optionsCookies);
        Cookies.set('userPhone', payload.userPhone, optionsCookies);
        Cookies.set('availabilityRuleFields', JSON.stringify(payload.availabilityRuleFields), optionsCookies);
        Cookies.set('userImageUrl', payload.userImageUrl, optionsCookies);
        LocalStorage.set('userImageBase64', payload.userImageBase64);
      });
  },

  async changePassword(context,
    payload: { userId: number, password: string, newPassword: string }) {
    return api
      .patch(`/users/p/${payload.userId}/change-password`, payload).then(async (response: AxiosResponse<UserNameInfoInterface>) => {
        if (response.data.login) {
          await context.dispatch('reset');
          void context.dispatch('user/login', {
            email: response.data.login,
            password: payload.newPassword,
          }, { root: true });
        }
      });
  },
  async resetPassword(context, payload: { password: string, resetToken: string }) {
    return api
      .post('/users/p/reset-password', payload);
  },

  sendMailToResetPassword(context, payload: { userEmail: string, url: string }) {
    return api
      .post('/users/send-mail-reset-password', payload);
  },

  async checkPasswordTokenExpiration(context, payload: { resetToken: string }) {
    return api
      .get(`/users/check-reset-password-token/${payload.resetToken}`);
  },
};

export default actions;
